/**
 * Kasuta nii:
 * import http from "@/http"
 * http.post("/tutorials", body);
 *
 * Initsialiseeri mixini seest http.initInterceptors() abil
 */

import axios from "axios"
import {updateWidgets} from "@/utils";

const instance = axios.create({
	baseURL: process.env.VUE_APP_BASEURL,
//	withCredentials: true,//Authorization&Cookie päiste jaoks
	headers: {
		"Content-type": "application/json"
	},
})

export default {
	get: instance.get,
	post: instance.post,
	put: instance.put,
	delete: instance.delete,
	initInterceptors: handlers => {
		instance.interceptors.request.use(
			config => {
				const jwt = window.localStorage.getItem('jwt')
				if (jwt)
					config.headers.Authorization = `Bearer ${jwt}`
				handlers.sendRequest(config)
				return config
			},
			error => Promise.reject(error)
		)
		instance.interceptors.response.use(
			response => {
				if (response.headers.authorization) {
					const jwt = response.headers.authorization.split(' ')[1]
					window.localStorage.setItem('jwt', jwt)
				}
				handlers.responseOk(response)
				document.getElementById("loading_progress").style.display = 'none'
				setTimeout(updateWidgets, 100)
				setTimeout(updateWidgets, 500)
				return response
			},
			error => {
				if (!error.response)
					handlers.timeout()
				else switch(error.response.status) {
					case 403: window.router.push('/login'); break
					case 422: handlers.response422(error); break
					default: handlers.responseOtherError(error); break
				}
				document.getElementById("loading_progress").style.display = 'none'
				return Promise.reject(error)
			}
		)
	},
}
