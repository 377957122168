<template>
  <div>
    <div class="row">
      <form-select id="serviceId" v-model="serviceId" :label="$t('service.serviceProvider')" css="s12 m4" :options="services" :has-empty-option="false">
        <option value="1">{{ $t('dashboard.service1') }}</option>
        <option value="2">{{ $t('dashboard.service2') }}</option>
        <option value="3">{{ $t('dashboard.service3') }}</option>
        <option value="4">{{ $t('dashboard.service4') }}</option>
      </form-select>
      <div class="col s12 m8">
        <div class="curl-container">
          <h2>{{ $t('dashboard.curl') }} <span class="copy-span" @click="onCopy"><i class="fas fa-copy" :title="$t('dashboard.curlCopy')"/></span></h2>
          <pre>{{ curCurl }}</pre>
        </div>
      </div>
    </div>
    <div class="row">
      <h2>{{ $t('dashboard.selectMethod') }}</h2>
    </div>
    <div class="row center-align">
      <div class="col s12 m4">
        <button class="btn waves-effect waves-light" type="submit" name="action" @click="onValidTicket">
          <i class="fas fa-money-bill"/> {{ $t('dashboard.btnValid') }}
        </button>
      </div>
      <div class="col s12 m4">
        <button class="btn waves-effect waves-light" type="submit" name="action" @click="onInvalidTicket">
          <i class="fas fa-user-slash"/> {{ $t('dashboard.btnInvalid') }}
        </button>
      </div>
      <div class="col s12 m4">
        <button class="btn waves-effect waves-light" type="submit" name="action" @click="onDiscountTicket">
          <i class="fas fa-money-bill-trend-up"/> {{ $t('dashboard.btnDiscount') }}
        </button>
      </div>
    </div>

    <div class="row ticket" :class="{invalid: type==='invalid'}" v-if="ticketNo">
      <div class="col s12 m6 center-align">
        <div class="ticket-no">#{{ ticketNo }}</div>
        <div><img :src="qrCodeUrl"/></div>
      </div>
      <div class="col s12 m6 center-align">
        <div class="ticket-title">{{$t('dashboard.event')}}</div>
        <div class="ticket-type" v-if="type==='discounted'">{{$t('dashboard.halfPrice')}}</div>
        <div class="ticket-type" v-else>{{$t('dashboard.fullPrice')}}</div>
        <div class="emit-time">{{$t('dashboard.emitted')}} <i>{{ new Date().toTimeString().substring(0, 5) }}</i></div>
        <div class="emit-time">{{$t('dashboard.emittedBy')}} <i>{{ $t(`dashboard.service${serviceId}`) }}</i></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import http from '@/http'
import {computed, onMounted, ref, watch} from "vue";
import {hideSpinner, updateWidgets, showSuccessToast} from "@/utils";
import FormSelect from "@/components/widgets/FormSelect";

const services = []
const serviceId = ref('1')
const ticketNo = ref(null)
const type = ref(null)
const curls = [
`curl -X GET \\
  'https://ticket.skemtek.com/api/validate1/_TICKET_'
`,
`curl -X POST \\
  'https://ticket.skemtek.com/api/validate2' \\
  -H 'Authorization: Basic bXl1c2VyOm15cGFzcw==' \\
  -H 'Content-Type: application/json' \\
  -d '{
  "code": "_TICKET_"
}'`,
`curl -X GET \\
  'https://ticket1.skemtek.com/api/validate3' \\
  --insecure \\
  -H 'X-TICKET-NO: _TICKET_'`,
`curl -X POST \\
  'https://ticket.skemtek.com/api/validate4' \\
  -d '{
  "code": "_TICKET_"
}'`,
]
onMounted(() => {
  hideSpinner()
  updateWidgets()
})
const updateTicket = response => ticketNo.value = response.data.ticketNo
const onValidTicket = () => {type.value = 'valid'; http.post(`/ticket/${serviceId.value}`).then(updateTicket)}
const onInvalidTicket = () => {type.value = 'invalid'; http.post(`/invalid-ticket/${serviceId.value}`).then(updateTicket)}
const onDiscountTicket = () => {type.value = 'discounted'; http.post(`/discount-ticket/${serviceId.value}`).then(updateTicket)}
const qrCodeUrl = computed(() => `${process.env.VUE_APP_BASEURL}/qrcode/${encodeURIComponent(ticketNo.value)}`)
const curCurl = computed(() => curls[serviceId.value - 1])
const onCopy = () => {navigator.clipboard.writeText(curCurl.value); showSuccessToast('dashboard.curlCopied')}
watch(serviceId, () => ticketNo.value = null)
</script>

<style scoped lang="scss">
button {margin-bottom: 1rem}
.ticket {color: black; max-width: 800px; padding: 2rem}
.ticket.invalid {color: #DDD}
.ticket-no {font-weight: bold; font-size: 1.2rem}
.ticket {border: 1px solid #888; border-radius: 6px}
.ticket-title {font-size: 4rem}
.ticket-type {font-size: 2rem}
.invalid .ticket-title {text-decoration: line-through}
.curl-container {
  padding: 1rem; overflow-x: auto;
  border-radius: 4px; border: 1px solid $bg-gray-span-color;
  background-color: lighten($bg-gray-span-color, 30%);
}
.copy-span {cursor: pointer; float: right}
</style>
