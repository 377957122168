import { computed } from "vue"
import M from "materialize-css"
import i18n from "@/i18n";

/**
 * Selle meetodi eesmärk on vähendada v-model boilerplate koodi.
 * Widgetites v-model'i otse kasutamine ei tööta, sest value-property kohta tuleb veateade: cannot mutate... .
 * Seepärast ongi vaja modelValue asemel kasutada computed composedValue't
 * Vt https://vanoneang.github.io/article/v-model-in-vue3.html#turn-it-into-a-composable
 */
export function useComposedValue(props, emit, name = 'modelValue') {
	return computed({
		get: () => props[name],
		set: value => emit(`update:${name}`, value)
	})
}

export function hideSpinner() {document.getElementById("loading_progress").style.display = 'none'}

export function showErrorToast(messageKey, param) {
//	console.log('CP-I18N', JSON.parse(JSON.stringify(i18n)), messageKey, param)
//	console.log('CP-I18N-T', i18n.$t, i18n.t, i18n.global.$t, i18n.global.t)
	const tpt = i18n.global.t(messageKey)
	const message = (param ? tpt.replace('#', param) : tpt)
	const html = `<i class="fas fa-triangle-exclamation fa-2x"></i> ${message}`
	M.toast({html: html, classes: 'error-toast', displayLength: 5000})
}

export function showSuccessToast(messageKey, param) {
	const tpt = i18n.global.t(messageKey)
	const message = (param ? tpt.replace('#', param) : tpt)
	const html = `<i class="fas fa-check fa-2x"></i> ${message}`
	M.toast({html: html, classes: 'success-toast', displayLength: 5000})
}

export function updateWidgets() {
	M.updateTextFields()//text inputtide labelid väikseks
	M.Tooltip.init(document.querySelectorAll('.tooltipped'), {enterDelay: 500, exitDelay: 1000})
	M.Tabs.init(document.querySelectorAll('.tabs'))
	hideSpinner()
}

export function setInputFocus() {
	const widgets = document.querySelectorAll('textarea, input')
	for(const widget of widgets) {
		if (widget.classList.contains('select-dropdown'))//ignoreeri dropdown-sid, kuna enamasti on seal mõistlik default juba olemas
			continue
		widget.focus();
		return
	}
}
