/*
Hoiab vormi valideerimisvigu. Loogika on selline:
1) axios http response interceptor registreerib siia väljade vead (updateErrors)
2) iga vormi widget defineerib endale computed propertid, mis siit getFieldMessage abil vigu pärivad
3) kui vigade komplekt muutub, siis fixSelectErrors() parandab Materialize SELECT-ides vigade kuvamise
 */

import {reactive} from "vue"
import i18n from "@/i18n"

const reactErrors = reactive({errors: {}})

function fixSelectErrors() {
	// Materialize/vue ei suuda korralikult SELECT'de vigu kuvada.
	// See meetod propageerib SELECT-st invalid-classi vastavasse SELECT'i wrapperisse (või siis eemaldab sealt)
	setTimeout(() => {
		document.querySelectorAll('.select-wrapper > select').forEach(selectEl => {
			if (selectEl.classList.contains('invalid'))
				selectEl.parentNode.classList.add('invalid')
			else selectEl.parentNode.classList.remove('invalid')
		})
	}, 100)
}

export default {
	updateErrors(errors) {reactErrors.errors = errors; fixSelectErrors()},
	hasError(id) {return reactErrors.errors[id]},
	getFieldMessage(id) {return i18n.getError(reactErrors.errors[id])}
}
