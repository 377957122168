<template>
  <div>
    <!-- Tavasuuruses menüü -->
    <div class="navbar-fixed">
      <nav class="nav-extended">
        <div class="nav-wrapper">
          <router-link to="/" data-target="mobile-demo" class="sidenav-trigger">
            <span class="hide-on-med-and-up"><i class="fas fa-bars"></i></span>
          </router-link>
          <router-link to="/" class="brand-logo">
            <div class="logo valign-wrapper"><img src="@/assets/skemtek-logo.svg"> {{ $t('dashboard.title') }}</div>
          </router-link>
          <ul id="nav-mobile" class="top-links right hide-on-small-and-down">
            <li class="lang-picker"><language-picker></language-picker></li>
          </ul>
        </div>
      </nav>
    </div>

    <!-- Mobiilne menüü -->
    <ul class="sidenav" id="mobile-demo">
      <li><div class="divider"></div></li>
      <li class="lang-picker"><language-picker :center="false" :dark-bg="false"></language-picker></li>
    </ul>
  </div>
</template>

<script setup>
import M from 'materialize-css'
import LanguagePicker from '@/components/LanguagePicker'
import {onMounted} from "vue"

window.serviceId = 1

onMounted(() => {
  const sideNavEl = document.querySelectorAll('.sidenav')
  M.Sidenav.init(sideNavEl, {closeOnClick: true})
  M.Sidenav.getInstance(sideNavEl.item(0))
})
</script>

<style>
nav input {color: white}
nav .select-wrapper .caret {fill: white}
.select-wrapper input.select-dropdown {border-bottom: 1px solid white}
</style>

<style scoped lang="scss">
a svg.svg-inline--fa {font-size: 1.5rem; height: 1.5rem; vertical-align: sub}
nav a svg.svg-inline--fa, nav a, nav svg.svg-inline--fa, nav a svg.svg-inline--fa {color: white}
.sidenav a, .sidenav svg.svg-inline--fa {color: $text-color}
a:hover {background-color: darken($primary-color, 10%); transition: background-color 1s}
.top-links a {color: white}
.brand-logo {margin-left: 1rem}
a.brand-logo, .logo {height: 100%}
.logo img {height: 40px; margin-top: 8px; margin-right: 3rem}
.lang-picker {margin: 0.75rem 0rem -0.75rem 2rem}
.sidenav a.active {font-weight: bold}
nav .brand-logo {left: 0px !important; transform: translateX(0)}
@media only screen and (max-width: $small-screen) {.brand-logo {margin-left: 5rem}}
.select-label {line-height: 42px; margin-right: 2rem}
</style>
