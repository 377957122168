export default {
	error: {
		unauthorized: '# is not allowed to login',
		missing: 'Missing',
		invalid: 'Invalid',
		too_long: 'Too long',
		too_short: 'Too short',
		too_small: 'Too small',
		too_big: 'Too big',
		serverTimeout: 'Unable to contact server',
		serverError: 'Something went wrong. Try restarting browser!',
	},
	picker: {
		cancel: 'Cancel',
		clear: 'Clear',
		done: 'Done',
		months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		weekDays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		weekdaysAbbrev: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
		firstDay: 0,
		twelveHour: true,
	},
	dashboard: {
		title: 'Ticket portal',
		btnValid: 'Valid',
		btnInvalid: 'Invalid',
		btnDiscount: 'Discounted',
		selectMethod: 'Select test ticket type',
		event: 'Event ticket',
		fullPrice: 'Full price €6.99',
		halfPrice: 'Half price €3.49',
		emitted: 'Issued at',
		emittedBy: 'Issued by',
		curl: 'Validation service cURL',
		curlCopy: 'Copy to clipboard',
		curlCopied: 'cURL was copied to clipboard',
		service1: 'Ticket issuer #1 (GET)',
		service2: 'Ticket issuer #2 (POST)',
		service3: 'Ticket issuer #3 (no cert)',
		service4: 'Ticket issuer #4 (slow)',
	},
	service: {
		serviceProvider: 'Pick ticket provider',
		name1: 'Ticket-Get',
		name2: 'Ticket Poster',
		name3: 'Darkside Ticket',
		name4: 'Slow Agency',
	},
	footer: {
		websocketLost: 'Server connection lost',
	},
}
