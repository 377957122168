<template>
  <div class="row" :class="{center: center, 'dark-bg': darkBg}">
    <img src="@/assets/est128.png" class="circle lang" title="Eesti" @click="ch('et')" :class="{active: locale==='et'}">
    <img src="@/assets/usen128.png" class="circle lang" title="English" @click="ch('en')" :class="{active: locale==='en'}">
  </div>
</template>

<script setup>
import {ref, defineProps, onMounted} from "vue"
import i18n from "@/i18n"

defineProps({center: {type: Boolean, default: true}, darkBg: {type: Boolean, default: true}})
const locale = ref(null)
onMounted(() => locale.value = i18n.getLocale())
const ch = newLocale => {
  i18n.setLocale(newLocale)
  locale.value = newLocale
  localStorage.setItem('locale', newLocale)
  //axios.defaults.headers.common['Accept-Language'] = locale
  document.querySelector('html').setAttribute('lang', newLocale)
}
</script>

<style scoped lang="scss">
.lang.circle {border: 2px solid transparent; width:32px; height: 32px; margin-right: 0.5rem; opacity: 0.75}
.lang.circle.active {border: 2px solid $text-color; opacity: 1;}
.dark-bg .lang.circle.active {border: 2px solid #fff; opacity: 1;}
.lang.circle:hover {opacity: 1; transition: all 0.5s}
.row {margin-bottom: 0px}
img {cursor: pointer}
</style>
