export default {
	error: {
		unauthorized: '# ei tohi siseneda',
		missing: 'Täitmata',
		invalid: 'Ei sobi',
		too_long: 'Liiga pikk',
		too_short: 'Liiga lühike',
		too_small: 'Liiga väike',
		too_big: 'Liiga suur',
		serverTimeout: 'Praegu ei saa serveriga ühendust',
		serverError: 'Nüüd läks midagi valesti. Proovi brauser taaskäivitada!',
	},
	picker: {
		cancel: 'Tühista',
		clear: 'Puhasta',
		done: 'Ok',
		months: ['Jaanuar', 'Veebruar', 'Märts', 'Aprill', 'Mai', 'Juuni', 'Juuli', 'August', 'September', 'Oktoober', 'November', 'Detsember'],
		monthsShort: ['Jaan', 'Veeb', 'Mär', 'Apr', 'Mai', 'Juun', 'Juul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dets'],
		weekDays: ['Pühapäev', 'Esmaspäev', 'Teisipäev', 'Kolmapäev', 'Neljapäev', 'Reede', 'Laupäev'],
		weekdaysShort: ['Püh', 'Esm', 'Tei', 'Kol', 'Nel', 'Ree', 'Laup'],
		weekdaysAbbrev: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
		firstDay: 1,
		twelveHour: false,
	},
	dashboard: {
		title: 'Piletiportaal',
		btnValid: 'Kõlblik',
		btnInvalid: 'Kasutatud',
		btnDiscount: 'Sooduspilet',
		selectMethod: 'Vali testipileti tüüp',
		event: 'Ürituse pilet',
		fullPrice: 'Täispilet 6,99€',
		halfPrice: 'Sooduspilet 3,49€',
		emitted: 'Väljastatud',
		emittedBy: 'Väljastaja',
		curl: 'Valideerimisteenuse cURL',
		curlCopy: 'Kopeeri lõikelauale',
		curlCopied: 'cURL kopeeriti lõikelauale',
		service1: 'Piletimüüja 1 (GET)',
		service2: 'Piletimüüja 2 (POST)',
		service3: 'Piletimüüja 3 (serditu)',
		service4: 'Piletimüüja 4 (aeglane)',
	},
	service: {
		serviceProvider: 'Vali piletimüüja',
		name1: 'Pileti-GET',
		name2: 'Piletipost',
		name3: 'Tumepiletid',
		name4: 'Teosammu piletid',
	},
	footer: {
		websocketLost: 'Ei saa serveriga ühendust',
	},
}
